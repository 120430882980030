export const formatNumber = (value) => {
  if (typeof value !== 'number') {
    value = parseFloat(value);
  }
  // Format the number with a space as a thousand separator
  return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 }).replace(/,/g, ' ');
};

export const unformatNumber = (value) => {
  return value.replace(/\s/g, '');
};