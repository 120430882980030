import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { calculateRegression } from '../../utils/regressionUtils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../styles/global.css'; // Ensure this path is correct

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RegressionAnalysis = ({ onRegressionData }) => {
    const estimates = useSelector((state) => state.projectInfo.estimates);

    const formattedData = estimates.flatMap(estimate => [10, 50, 90].map(probability => ({
        pricePerUnit: parseFloat(estimate[`probability${probability}`]),
        probability,
        name: estimate.name
    })));

    const sortedData = formattedData.sort((a, b) => a.pricePerUnit - b.pricePerUnit);
    const regressionData = calculateRegression(sortedData);

    useEffect(() => {
        if (formattedData.length > 0) {
            onRegressionData(regressionData);
        }
    }, [formattedData, onRegressionData, regressionData]);

    if (formattedData.length === 0) {
        return <div>No data available</div>;
    }

    const minPrice = Math.min(...sortedData.map(d => d.pricePerUnit)) * 0.9;
    const maxPrice = Math.max(...sortedData.map(d => d.pricePerUnit)) * 1.1;

    const chartData = {
        labels: sortedData.map(d => d.pricePerUnit),
        datasets: [
            {
                label: 'Price per Unit',
                data: sortedData.map(d => ({ x: d.pricePerUnit, y: d.probability, name: d.name })),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 1)',
                pointRadius: 12,
                showLine: false,
            },
            {
                label: 'Best Fitted Line',
                data: regressionData.regressionLine.map(point => ({ x: point.x, y: point.y })),
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 3,
                backgroundColor: 'rgba(255, 99, 132, 1)',
                type: 'line',
                fill: false,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: false, // Allow the chart to resize freely
        responsive: true, // Ensure the chart is responsive
        layout: {
            padding: {
                bottom: 40
            }
        },
        scales: {
            y: {
                title: { display: true, text: 'Probability of winning' },
                min: 0,
                max: 100,
                ticks: { stepSize: 10 },
            },
            x: {
                title: { display: true, text: 'Price pr. Unit' },
                min: minPrice,
                max: maxPrice,
                type: 'linear',
            },
        },
        plugins: {
            tooltip: {
                animation: false,
                callbacks: {
                    label: function(tooltipItem) {
                        return `${tooltipItem.raw.y} (${tooltipItem.raw.name})`;
                    }
                }
            }
        },
        animation: {
            duration: 35
        }
    };

    return (
        <Box className="regression-analysis-container">
            <Typography variant="h6" component="h2" className="regression-analysis-typography">Regression (best fitted line)</Typography>
            <Line data={chartData} options={chartOptions} className="regression-analysis-chart" />
            <div className="regression-analysis-equation">
                <p>Linear Regression Equation: {regressionData.equation}, R²: {regressionData.rSquared}, Slope: {regressionData.slope.toFixed(2)}, Intercept: {regressionData.intercept.toFixed(2)}</p>
            </div>
        </Box>
    );
};

export default RegressionAnalysis;