import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEstimate } from '../../redux/actions/projectActions';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatNumber } from '../../utils/formatNumber';
import '../../styles/global.css'; // Ensure this path is correct

const DetailedEstimates = ({ selectedName, className }) => {
  const dispatch = useDispatch();
  const projectInfo = useSelector((state) => state.projectInfo);
  const estimates = useSelector((state) => state.projectInfo.estimates.find(est => est.name === selectedName)) || { probability10: 0, probability50: 0, probability90: 0 };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedEstimate = {
      ...estimates,
      [name]: value,
    };
    dispatch(updateEstimate(updatedEstimate));
  };

  return (
    <Box className={className} sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2, boxShadow: 1 }}>
      <Typography variant="h6" component="h2">Estimates for {selectedName}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="detailed estimates table">
          <TableHead>
            <TableRow>
              <TableCell>% Win</TableCell>
              <TableCell>Price per Unit</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>#Units</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Contribution Margin</TableCell>
              <TableCell>CM %</TableCell>
              <TableCell>Full Cost Margin</TableCell>
              <TableCell>FCM %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[10, 50, 90].map((probability) => (
              <TableRow key={probability}>
                <TableCell>{probability} %</TableCell>
                <TableCell>
                  <TextField
                    name={`probability${probability}`}
                    type="number"
                    value={estimates[`probability${probability}`]}
                    onChange={handleChange}
                    size="small"
                    inputProps={{ step: '0.1' }}
                  />
                </TableCell>
                <TableCell>{projectInfo.unit}</TableCell>
                <TableCell>{formatNumber(projectInfo.numUnits)}</TableCell>
                <TableCell>{formatNumber((estimates[`probability${probability}`] * projectInfo.numUnits).toFixed(0))}</TableCell>
                <TableCell>{formatNumber(((estimates[`probability${probability}`] * projectInfo.numUnits) - projectInfo.variableCost).toFixed(0))}</TableCell>
                <TableCell>{(((estimates[`probability${probability}`] * projectInfo.numUnits) - projectInfo.variableCost) / (estimates[`probability${probability}`] * projectInfo.numUnits) * 100).toFixed(0)} %</TableCell>
                <TableCell>{formatNumber(((estimates[`probability${probability}`] * projectInfo.numUnits) - projectInfo.variableCost - projectInfo.fixedCost).toFixed(1))}</TableCell>
                <TableCell>{((((estimates[`probability${probability}`] * projectInfo.numUnits) - projectInfo.variableCost - projectInfo.fixedCost) / (estimates[`probability${probability}`] * projectInfo.numUnits)) * 100).toFixed(0)} %</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedEstimates;