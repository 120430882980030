import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatNumber } from '../../utils/formatNumber';

const ProbabilityVsContributionMarginTable = ({ regressionData }) => {
  const projectInfo = useSelector((state) => state.projectInfo);


  // Generate rows for the table
  const rows = Array.from({ length: 41 }, (_, i) => {
    const winPercentage = i * 2.5;
    const pricePerUnit = (winPercentage - regressionData.intercept) / regressionData.slope;
    const totalPrice = pricePerUnit * projectInfo.numUnits;
    const contributionMargin = totalPrice - projectInfo.variableCost;
    const cmPercentage = (contributionMargin / totalPrice) * 100;
    const totalCostMargin = contributionMargin - projectInfo.fixedCost;
    const tcmPercentage = (totalCostMargin / totalPrice) * 100;
    const expectedCM = contributionMargin * (winPercentage / 100);
    const expectedTCM = totalCostMargin * (winPercentage / 100);

    return {
      winPercentage,
      pricePerUnit,
      totalPrice,
      contributionMargin,
      cmPercentage,
      totalCostMargin,
      tcmPercentage,
      expectedCM,
      expectedTCM,
    };
  });

  // Find the maximum values for highlighting
  const maxExpectedCM = Math.max(...rows.map(row => row.expectedCM));
  const maxExpectedTCM = Math.max(...rows.map(row => row.expectedTCM));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2, boxShadow: 1 }}>
      <Typography variant="h6" component="h2">% Winning vs. Contribution Margin</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="probability vs contribution margin table">
          <TableHead>
            <TableRow>
              <TableCell>% Win</TableCell>
              <TableCell>Price pr. Unit</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Contribution Margin</TableCell>
              <TableCell>CM %</TableCell>
              <TableCell>Total Cost Margin</TableCell>
              <TableCell>TCM %</TableCell>
              <TableCell>Expected CM</TableCell>
              <TableCell>Expected TCM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.winPercentage.toFixed(1)} %</TableCell>
                <TableCell>{formatNumber(row.pricePerUnit.toFixed(0))}</TableCell>
                <TableCell>{formatNumber(row.totalPrice.toFixed(0))}</TableCell>
                <TableCell>{formatNumber(row.contributionMargin.toFixed(0))}</TableCell>
                <TableCell>{row.cmPercentage.toFixed(1)} %</TableCell>
                <TableCell>{formatNumber(row.totalCostMargin.toFixed(0))}</TableCell>
                <TableCell>{row.tcmPercentage.toFixed(1)} %</TableCell>
                <TableCell style={{ backgroundColor: getColorScale(row.expectedCM, rows, 'expectedCM', maxExpectedCM) }}>{formatNumber(row.expectedCM.toFixed(0))}</TableCell>
                <TableCell style={{ backgroundColor: getColorScale(row.expectedTCM, rows, 'expectedTCM', maxExpectedTCM) }}>{formatNumber(row.expectedTCM.toFixed(0))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// Helper function to get color scale for highlighting
const getColorScale = (value, rows, key, maxValue) => {
  const values = rows.map(row => row[key]);
  const max = Math.max(...values);
  const min = Math.min(...values);
  const ratio = (value - min) / (max - min);
  const red = Math.floor(220 + (50 - 220) * ratio); // Interpolating between red and green
  const green = Math.floor(20 + (205 - 20) * ratio);
  const alpha = 0.5; // Adjust transparency here
  const backgroundColor = `rgba(${red}, ${green}, 60, ${alpha})`;

  // Highlight the maximum value
  if (value === maxValue) {
    return 'rgb(50,205,50)'; // Bright green
  }

  return backgroundColor;
};

export default ProbabilityVsContributionMarginTable;
