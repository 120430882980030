import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Navbar from './components/Navbar';
import ProbabilisticPricing from './pages/ProbabilisticPricing';
import Homepage from './pages/Homepage';
import theme from './theme';
import './styles/global.css';

const App = () => {
  const [reloadKey, setReloadKey] = useState(0); // State for forcing remount

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', width: '100%' }}>
          <Navbar setReloadKey={setReloadKey} />
          <Routes>
            <Route path="/" element={<Homepage key={reloadKey} />} />
            <Route path="/probabilistic-pricing" element={<ProbabilisticPricing />} />
            {/* Add other routes */}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
