import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setProjectInfo } from '../../redux/actions/projectActions';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatNumber, unformatNumber } from '../../utils/formatNumber';

const ProjectInfo = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.projectInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = name === 'numUnits' || name === 'variableCost' || name === 'fixedCost' ? unformatNumber(value) : value;
    dispatch(setProjectInfo({
      ...info,
      [name]: formattedValue,
    }));
  };

  return (
    <Box className="project-info">
      <Typography variant="h6" component="h2">Project Information</Typography>
      <TextField name="projectName" label="Project Name" onChange={handleChange} value={info.projectName} size="small" />
      <TextField name="customer" label="Customer" onChange={handleChange} value={info.customer} size="small" />
      <TextField name="unit" label="Unit of Pricing" onChange={handleChange} value={info.unit} size="small" />
      <TextField name="numUnits" label="Number of Units" type="text" onChange={handleChange} value={formatNumber(info.numUnits)} size="small" />
      <TextField name="variableCost" label="Variable Cost" type="text" onChange={handleChange} value={formatNumber(info.variableCost)} size="small" />
      <TextField name="fixedCost" label="Fixed Cost" type="text" onChange={handleChange} value={formatNumber(info.fixedCost)} size="small" />
    </Box>
  );
};

export default ProjectInfo;