import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEstimate, addEstimate, deleteEstimate } from '../../redux/actions/projectActions';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Estimates = ({ setSelectedName, detailedEstimates, setDetailedEstimates }) => {
  const dispatch = useDispatch();
  const estimates = useSelector((state) => state.projectInfo.estimates);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  // Load the selected name from local storage or select the first default name
  useEffect(() => {
    const savedSelectedName = localStorage.getItem('selectedName');
    if (savedSelectedName) {
      const index = estimates.findIndex(estimate => estimate.name === savedSelectedName);
      if (index !== -1) {
        setSelectedIndex(index);
        setSelectedName(savedSelectedName);
      }
    } else if (estimates.length > 0) {
      setSelectedIndex(0);
      setSelectedName(estimates[0].name);
      localStorage.setItem('selectedName', estimates[0].name); // Save the first default name to local storage
    }
  }, [estimates, setSelectedName]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newEstimates = [...estimates];
    const oldName = newEstimates[index].name;
    newEstimates[index][name] = value;
    dispatch(updateEstimate(newEstimates[index]));
    if (name === 'name') {
      setDetailedEstimates((prev) => {
        const newDetailedEstimates = { ...prev };
        newDetailedEstimates[value] = newDetailedEstimates[oldName] || { probability10: 0, probability50: 0, probability90: 0 };
        delete newDetailedEstimates[oldName];
        return newDetailedEstimates;
      });
      if (selectedIndex === index) {
        setSelectedName(value);
        localStorage.setItem('selectedName', value); // Save the selected name to local storage
      }
    }
  };

  const handleAddRow = () => {
    const newName = `Name ${estimates.length + 1}`;
    const newEstimate = { name: newName, probability10: 0, probability50: 0, probability90: 0 };
    dispatch(addEstimate(newEstimate));
    setDetailedEstimates((prev) => ({
      ...prev,
      [newName]: { probability10: 0, probability50: 0, probability90: 0 },
    }));
    setSelectedIndex(estimates.length);
    setSelectedName(newName);
    localStorage.setItem('selectedName', newName); // Save the selected name to local storage
  };

  const handleDeleteRow = (index) => {
    const nameToDelete = estimates[index].name;
    dispatch(deleteEstimate(nameToDelete));
    setDetailedEstimates((prev) => {
      const newDetailedEstimates = { ...prev };
      delete newDetailedEstimates[nameToDelete];
      return newDetailedEstimates;
    });
    const newEstimates = estimates.filter((_, i) => i !== index);
    if (selectedIndex === index) {
      const newIndex = newEstimates.length > 0 ? 0 : null;
      setSelectedIndex(newIndex);
      setSelectedName(newIndex !== null ? newEstimates[newIndex].name : null);
      localStorage.setItem('selectedName', newIndex !== null ? newEstimates[newIndex].name : ''); // Save the selected name to local storage
    } else if (selectedIndex > index) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleSelectName = (index) => {
    setSelectedIndex(index);
    setSelectedName(estimates[index].name);
    localStorage.setItem('selectedName', estimates[index].name); // Save the selected name to local storage
  };

  return (
    <Box className="estimates">
      <Typography variant="h6" component="h2">Estimates</Typography>
      {estimates.map((estimate, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Checkbox
            checked={selectedIndex === index}
            onChange={() => handleSelectName(index)}
          />
          <TextField name="name" label="Name" value={estimate.name} onChange={(e) => handleChange(index, e)} size="small" />
          <IconButton onClick={() => handleDeleteRow(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <IconButton onClick={handleAddRow}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default Estimates;