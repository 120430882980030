// Function to save the state to localStorage
export const saveState = (state) => {
    try {
      // Convert the state object to a JSON string
      const serializedState = JSON.stringify(state);
      // Save the serialized state to localStorage with the key 'appState'
      localStorage.setItem('appState', serializedState);
    } catch (err) {
      // Log an error message if saving the state fails
      console.error('Could not save state', err);
    }
  };
  
  // Function to load the state from localStorage
  export const loadState = () => {
    try {
      // Retrieve the serialized state from localStorage
      const serializedState = localStorage.getItem('appState');
      // If no state is found, return undefined
      if (serializedState === null) {
        return undefined;
      }
      // Parse the JSON string back into an object and return it
      return JSON.parse(serializedState);
    } catch (err) {
      // Log an error message if loading the state fails
      console.error('Could not load state', err);
      return undefined;
    }
  };