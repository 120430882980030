import { createStore, combineReducers } from 'redux';
import projectReducer from './reducers/projectReducer'; // Import the project reducer
import { saveState, loadState } from '../utils/storageService'; // Import the storage service

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  projectInfo: projectReducer,
  // other reducers can be added here
});

// Load the state from localStorage
const persistedState = loadState();

// Create the Redux store and enable the Redux DevTools extension
const store = createStore(
  rootReducer,
  persistedState, // Use the persisted state as the initial state
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Subscribe to store updates and save the state to localStorage whenever it changes
store.subscribe(() => {
  saveState(store.getState());
});

export default store;