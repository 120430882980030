import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setProjectInfo } from '../../redux/actions/projectActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1.2rem',
    maxWidth: '600px',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    borderRadius: '8px',
  },
});

const NewAndSave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  // Clear the localStorage and reset the project info
  const handleNewProject = () => {
    localStorage.clear();
    dispatch(setProjectInfo({
      projectName: '',
      customer: '',
      unit: '',
      numUnits: 0,
      variableCost: 0,
      fixedCost: 0,
      estimates: [{ name: 'Name', probability10: 0, probability50: 0, probability90: 0 }],
      averages: { 10: 0, 50: 0, 90: 0 },
      pricePerUnit: 0,
    }));
    navigate('/probabilistic-pricing', { replace: true });
  };

  // Handle saving the email and sending it via API
  const handleSave = () => {
    if (!email) {
      alert('Please enter an email address');
      return;
    }

    const sessionLink = window.location.href; // Current session link
    axios.post('/send-email', { email, sessionLink })
      .then(response => {
        console.log('Email sent:', response.data);
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 2,
        padding: '5px', // Add padding to fit within the green border
      }}
    >
      {/* Button to reset the project */}
      <Button
        variant="contained"
        onClick={handleNewProject}
        sx={{
          backgroundColor: 'black',
          color: 'white',
          fontSize: '1.2rem',
          padding: '6px 24px',
        }}
      >
        New Project
      </Button>

      {/* Email input field */}
      <TextField
        label="Email Address"
        size="small"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{
          input: { fontSize: '1.2rem' },
          label: { fontSize: '1.2rem' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'black' },
            '&:hover fieldset': { borderColor: 'black' },
            '&.Mui-focused fieldset': { borderColor: 'black' },
          },
        }}
      />

      {/* Tooltip for the Save icon */}
      <CustomTooltip
        title="Enter your email address and hit save to save the estimates. You will receive a link via email."
        arrow
      >
        <IconButton
          sx={{ color: 'black', fontSize: '4rem', marginLeft: '-16px' }}
          onClick={handleSave}
        >
          <SaveIcon fontSize="inherit" />
        </IconButton>
      </CustomTooltip>
    </Box>
  );
};

export default NewAndSave;