// Action type constants
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
export const ADD_ESTIMATE = 'ADD_ESTIMATE';
export const UPDATE_ESTIMATE = 'UPDATE_ESTIMATE';
export const DELETE_ESTIMATE = 'DELETE_ESTIMATE';
export const SET_AVERAGES = 'SET_AVERAGES';
export const UPDATE_PRICE_PER_UNIT = 'UPDATE_PRICE_PER_UNIT'; // Ensure this line is present

// Action creators
export const setProjectInfo = (info) => ({
  type: SET_PROJECT_INFO,
  payload: info,
});

export const addEstimate = (estimate) => ({
  type: ADD_ESTIMATE,
  payload: estimate,
});

export const updateEstimate = (estimate) => ({
  type: UPDATE_ESTIMATE,
  payload: estimate,
});

export const deleteEstimate = (name) => ({
  type: DELETE_ESTIMATE,
  payload: name,
});

export const setAverages = (averages) => ({
  type: SET_AVERAGES,
  payload: averages,
});

export const updatePricePerUnit = (price) => ({
  type: UPDATE_PRICE_PER_UNIT,
  payload: price,
});