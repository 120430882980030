import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePricePerUnit } from '../../redux/actions/projectActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { formatNumber, unformatNumber } from '../../utils/formatNumber';

const Output = ({ regressionData, className }) => {
  const dispatch = useDispatch();
  const projectInfo = useSelector((state) => state.projectInfo);
  const pricePerUnit = useSelector((state) => state.projectInfo.pricePerUnit);
  const averages = useSelector((state) => state.projectInfo.averages);
  const [probability, setProbability] = useState(0);
  const userChangedPrice = useRef(false);

  useEffect(() => {
    if (regressionData.slope !== undefined && regressionData.intercept !== undefined) {
      setProbability(regressionData.slope * pricePerUnit + regressionData.intercept);
    }
  }, [pricePerUnit, regressionData.slope, regressionData.intercept]);

  useEffect(() => {
    if (!userChangedPrice.current) {
      dispatch(updatePricePerUnit(averages[50]));
    }
  }, [averages, dispatch]);

  const handlePriceChange = (e) => {
    userChangedPrice.current = true;
    dispatch(updatePricePerUnit(parseFloat(unformatNumber(e.target.value))));
  };

  const numUnits = parseFloat(projectInfo.numUnits) || 0;
  const variableCost = parseFloat(projectInfo.variableCost) || 0;
  const fixedCost = parseFloat(projectInfo.fixedCost) || 0;
  const totalPrice = pricePerUnit * numUnits;
  const contributionMargin = totalPrice - variableCost;
  const cmPercentage = (contributionMargin / totalPrice) * 100;
  const fullCostMargin = contributionMargin - fixedCost;
  const fcmPercentage = (fullCostMargin / totalPrice) * 100;
  const expectedContributionMargin = contributionMargin * (probability / 100);

  return (
    <Box className={className} sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2, boxShadow: 1 }}>
      <Typography variant="h6" component="h2">Output</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="output table">
          <TableHead>
            <TableRow>
              <TableCell>Price pr. unit</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>#Units</TableCell>
              <TableCell>Total price</TableCell>
              <TableCell>Contribution margin (CM)</TableCell>
              <TableCell>CM %</TableCell>
              <TableCell>Full cost margin (FCM)</TableCell>
              <TableCell>FCM %</TableCell>
              <TableCell>% Win</TableCell>
              <TableCell>Expected CM</TableCell>
              <TableCell>R² (Agreement)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  name="pricePerUnit"
                  type="text"
                  value={formatNumber(pricePerUnit)}
                  onChange={handlePriceChange}
                  inputProps={{ step: '0.1' }}
                  size="small"
                />
              </TableCell>
              <TableCell>{projectInfo.unit}</TableCell>
              <TableCell>{formatNumber(numUnits.toFixed(0))}</TableCell>
              <TableCell>{formatNumber(totalPrice.toFixed(0))}</TableCell>
              <TableCell>{formatNumber(contributionMargin.toFixed(0))}</TableCell>
              <TableCell>{cmPercentage.toFixed(1)}</TableCell>
              <TableCell>{formatNumber(fullCostMargin.toFixed(0))}</TableCell>
              <TableCell>{fcmPercentage.toFixed(1)}</TableCell>
              <TableCell>{probability.toFixed(1)}</TableCell>
              <TableCell>{formatNumber(expectedContributionMargin.toFixed(0))}</TableCell>
              <TableCell>{regressionData.rSquared}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Output;