import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import './styles/global.css'; // Import global styles

const container = document.getElementById('root');
const root = createRoot(container);

// Wrap the App component with the Provider component to make the Redux store available to the entire app
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);