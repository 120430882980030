import React from 'react';
import '../styles/global.css';
import homePageImage from '../assets/home-page.png';

const Homepage = () => {
  return (
    <div className="homepage">
      <img src={homePageImage} alt="Home Page" className="homepage-image" />
      <div className="header">
        <h1>Learn more about Pricing</h1>
        <p>TRENDING INSIGHTS</p>
      </div>
      <div className="bottom-ribbon">
        <a href="#1" className="link"><span className="number">1</span><span className="text">What I learned from Daniel Kahneman</span></a>
        <a href="#2" className="link"><span className="number">2</span><span className="text">The next big accelartors in pricing</span></a>
        <a href="#3" className="link"><span className="number">3</span><span className="text">Why pricing is getting tougher—and how to break through</span></a>
        <a href="#4" className="link"><span className="number">4</span><span className="text">The art of 21st-century pricing: From ambition to building success</span></a>
      </div>
    </div>
  );
};

export default Homepage;