import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h6: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: '1.5rem',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem', // Adjust the font size for table cells
          textAlign: 'center', // Center align text
        },
        head: {
          fontSize: '1.5rem', // Adjust the font size for table headers
          fontWeight: 'bold', // Optional: make headers bold
          textAlign: 'center', // Center align text
        },
        body: {
          fontSize: '1.5rem', // Adjust the font size for table body cells
          textAlign: 'center', // Center align text
        },
      },
    },
  },
});

export default theme;
