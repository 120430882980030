import React, { useState } from 'react';
import ProjectInfo from '../components/ProbabilisticPricing/ProjectInfo';
import Estimates from '../components/ProbabilisticPricing/Estimates';
import DetailedEstimates from '../components/ProbabilisticPricing/DetailedEstimates';
import EstimatesOverview from '../components/ProbabilisticPricing/EstimatesOverview';
import RegressionAnalysis from '../components/ProbabilisticPricing/RegressionAnalysis';
import Output from '../components/ProbabilisticPricing/Output';
import ProbabilityVsContributionMarginTable from '../components/ProbabilisticPricing/ProbabilityVsContributionMarginTable';
import NewAndSave from '../components/ProbabilisticPricing/NewAndSave';
import '../styles/global.css';

const ProbabilisticPricing = () => {
  const [selectedName, setSelectedName] = useState(null);
  const [detailedEstimates, setDetailedEstimates] = useState({});
  const [regressionData, setRegressionData] = useState(null);
  const [sortedEstimates, setSortedEstimates] = useState([]);
  const allPricesFilled = sortedEstimates.every(
    (d) => d.pricePerUnit !== undefined && d.pricePerUnit !== ''
  );

  const handleRegressionData = (data) => {
    setRegressionData(data);
  };

  return (
    <div
      style={{
        marginTop: '60px', // Remove margin to tighten space
        padding: '0px 0px', // Adjust padding to tighten space
      }}
    >
      {/* Render NewAndSave directly here */}
      <NewAndSave />

      {/* First Section: ProjectInfo, Estimates, and DetailedEstimates */}
      <div className="container" style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
        <ProjectInfo />
        <Estimates
          setSelectedName={setSelectedName}
          detailedEstimates={detailedEstimates}
          setDetailedEstimates={setDetailedEstimates}
        />
        <DetailedEstimates
          selectedName={selectedName}
          detailedEstimates={detailedEstimates}
          setDetailedEstimates={setDetailedEstimates}
        />
      </div>

      {/* Second Section: EstimatesOverview, RegressionAnalysis */}
      <div className="container" style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
        <EstimatesOverview
          selectedName={selectedName}
          detailedEstimates={detailedEstimates}
          onSortedEstimates={setSortedEstimates}
        />
        <RegressionAnalysis
          data={sortedEstimates}
          onRegressionData={handleRegressionData}
        />
      </div>

      {/* Output and ProbabilityVsContributionMarginTable */}
      {allPricesFilled && regressionData && (
        <Output className="container" regressionData={regressionData} />
      )}

      {regressionData && (
        <ProbabilityVsContributionMarginTable
          className="container"
          regressionData={regressionData}
        />
      )}
    </div>
  );
};

export default ProbabilisticPricing;