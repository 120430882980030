import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/', { replace: true }); // Navigate to the homepage
    window.location.reload(); // Force a full page reload to reset any state
  };

  return (
    <nav className="navbar">
      <ul className="main-nav">
        {/* PriceKick Logo with click functionality */}
        <li className="nav-item pricekick">
          <span onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            PriceKick
          </span>
        </li>

        {/* Navigation Items */}
        <li className="nav-item">
          <Link to="/industries">Industries</Link>
          <ul className="sub-nav">
            <li>
              <Link to="/industries/construction">Construction</Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/insights">Insights</Link>
          <ul className="sub-nav">
            <li>
              <Link to="/insights/tactics">Tactics</Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/tools">Tools</Link>
          <ul className="sub-nav">
            <li>
              <Link to="/probabilistic-pricing">Probabilistic pricing</Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
