// src/utils/regressionUtils.js
export const calculateRegression = (data) => {
  const n = data.length;
  const sumX = data.reduce((sum, d) => sum + d.pricePerUnit, 0);
  const sumY = data.reduce((sum, d) => sum + d.probability, 0);
  const sumXY = data.reduce((sum, d) => sum + d.pricePerUnit * d.probability, 0);
  const sumX2 = data.reduce((sum, d) => sum + d.pricePerUnit * d.pricePerUnit, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  const regressionLine = data.map(d => ({
    x: d.pricePerUnit,
    y: slope * d.pricePerUnit + intercept,
  }));

  const ssTotal = data.reduce((sum, d) => sum + (d.probability - sumY / n) ** 2, 0);
  const ssResidual = data.reduce((sum, d) => sum + (d.probability - (slope * d.pricePerUnit + intercept)) ** 2, 0);
  const rSquared = 1 - ssResidual / ssTotal;

  const equation = `y = ${slope.toFixed(2)}x + ${intercept.toFixed(2)}`;

  return { regressionLine, equation, rSquared: rSquared.toFixed(2), slope, intercept };
};
