import {
  SET_PROJECT_INFO,
  ADD_ESTIMATE,
  UPDATE_ESTIMATE,
  DELETE_ESTIMATE,
  SET_AVERAGES,
  UPDATE_PRICE_PER_UNIT
} from '../actions/projectActions';

const initialState = {
  projectName: '',
  customer: '',
  unit: '',
  numUnits: 0,
  variableCost: 0,
  fixedCost: 0,
  estimates: [
    { name: 'Name', probability10: 0, probability50: 0, probability90: 0 }
  ],
  averages: { 10: 0, 50: 0, 90: 0 },
  pricePerUnit: 0,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_ESTIMATE:
      return {
        ...state,
        estimates: [...state.estimates, action.payload],
      };
    case UPDATE_ESTIMATE:
      return {
        ...state,
        estimates: state.estimates.map((estimate) =>
          estimate.name === action.payload.name ? action.payload : estimate
        ),
      };
    case DELETE_ESTIMATE:
      return {
        ...state,
        estimates: state.estimates.filter((estimate) => estimate.name !== action.payload),
      };
    case SET_AVERAGES:
      return {
        ...state,
        averages: action.payload,
      };
    case UPDATE_PRICE_PER_UNIT:
      return {
        ...state,
        pricePerUnit: action.payload,
      };
    default:
      return state;
  }
};

export default projectReducer;