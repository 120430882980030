import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { setAverages } from '../../redux/actions/projectActions';
import { formatNumber } from '../../utils/formatNumber';
import '../../styles/global.css'; // Ensure this path is correct

const EstimatesOverview = ({ selectedName, onSortedEstimates }) => {
  const dispatch = useDispatch();
  const estimates = useSelector((state) => state.projectInfo.estimates);

  // Flatten and sort the estimates by probability of winning
  const sortedEstimates = useMemo(() => estimates.flatMap(estimate =>
    [10, 50, 90].map(probability => ({
      name: estimate.name,
      probability,
      pricePerUnit: estimate[`probability${probability}`] ?? 0
    }))
  ).sort((a, b) => a.probability - b.probability), [estimates]);

  // Calculate averages
  const averages = useMemo(() => {
    const sums = { 10: 0, 50: 0, 90: 0 };
    const counts = { 10: 0, 50: 0, 90: 0 };
    sortedEstimates.forEach(({ probability, pricePerUnit }) => {
      const price = Number(pricePerUnit); // Convert pricePerUnit to a number
      if (price > 0) {
        sums[probability] += price;
        counts[probability] += 1;
      }
    });
    return {
      10: counts[10] ? (sums[10] / counts[10]).toFixed(0) : 0,
      50: counts[50] ? (sums[50] / counts[50]).toFixed(0) : 0,
      90: counts[90] ? (sums[90] / counts[90]).toFixed(0) : 0,
    };
  }, [sortedEstimates]);

  // Pass the sorted estimates and averages to the parent component
  useEffect(() => {
    if (onSortedEstimates) {
      onSortedEstimates(sortedEstimates);
    }
    dispatch(setAverages(averages));
  }, [sortedEstimates, averages, onSortedEstimates, dispatch]);

  return (
    <Box className="estimates-overview-container">
      <Typography variant="h6" component="h2" className="estimates-overview-typography">Overview</Typography>
      <Typography variant="subtitle1" component="h3" sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '8px' }}>Averages</Typography>
      <TableContainer component={Paper} className="estimates-overview-table-container">
        <Table aria-label="averages table">
          <TableHead>
            <TableRow>
              <TableCell>10 %</TableCell>
              <TableCell>50 %</TableCell>
              <TableCell>90 %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{formatNumber(averages[10])}</TableCell>
              <TableCell>{formatNumber(averages[50])}</TableCell>
              <TableCell>{formatNumber(averages[90])}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="subtitle1" component="h3" sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '8px' }}>Estimates</Typography>
      <TableContainer component={Paper} className="estimates-overview-table-container">
        <Table aria-label="estimates overview table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>% Win</TableCell>
              <TableCell>Price pr. Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEstimates.map((estimate, index) => (
              <TableRow key={`${estimate.name}-${estimate.probability}-${index}`}>
                <TableCell>{estimate.name}</TableCell>
                <TableCell>{estimate.probability} %</TableCell>
                <TableCell>{formatNumber(estimate.pricePerUnit)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EstimatesOverview;
